import React, { useState } from "react"
import { loadStripe } from "@stripe/stripe-js"
const stripePromise = loadStripe("pk_live_ZbTByA8WzTUuj7DYd8HvDw0t00nlXqtOpd");

const StripeButton = ({ displayPrice, price, name, location, images, linkRedirect }) => {
    const [loading, setLoading] = useState(false)
    const handleClick = async () => {
        setLoading(true)
        const stripe = await stripePromise;
        const response = await fetch('/.netlify/functions/stripe', {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                images: location.origin + images,
                unit_amount: price*100,
                url: location.origin,
                redirect: linkRedirect
            }),
            headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
            }
        })
  
        const session = await response.json();
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      };

    return (
        <button
            className="button is-rounded is-danger is-medium is-fullwidth p-3"
            disabled={loading}
            onClick={handleClick}
        >
            Payer par carte
        </button>
    )
}
export default StripeButton