import React from 'react';
import StripeButton from "../Checkout/stripeButton"
import PaypalButton from "../Checkout/paypalButton"
import DiscountForm from "../DiscountForm/DiscountForm"
const Modal = ({ openModal, setOpenModal, discount, setPromoCode, allDiscountCode, price, displayPrice, name, images="", location, linkRedirect }) => {
    return (
        <div className={"modal " + (openModal && "is-active")} style={{padding: "10px"}}>
            <div className="modal-background" onClick={() => setOpenModal(!openModal)}></div>
            <div className="modal-card">
                <section className="modal-card-body" style={{borderRadius: "10px"}}>
                    <h3 className="subtitle has-text-centered has-text-weight-semibold">{name}</h3>
                    <h3 className="subtitle has-text-centered has-text-weight-semibold">{displayPrice}</h3>
                    <hr />
                    <StripeButton name={name} price={price} displayPrice={displayPrice} images={images} location={location} linkRedirect={linkRedirect} />
                    <div className="columns is-centered is-mobile" style={{margin:"10px 0px"}}>
                        <div className="column"><hr /></div>
                        <div className="column"><p className="has-text-centered">OU</p></div>
                        <div className="column"><hr /></div>
                    </div>
                    <PaypalButton name={name} price={price}  linkRedirect={linkRedirect}  />
                    <hr />
                    <DiscountForm discount={discount} setPromoCode={setPromoCode} allDiscountCode={allDiscountCode}/>
                </section>
            </div>
            <button class="modal-close is-large" aria-label="close" onClick={() => setOpenModal(!openModal)}></button>
        </div>
    );
};

export default Modal;