import React, { useState } from 'react';
import Header from "../components/Courses/Header"
import Footer from "../components/Courses/Footer"
import Layout from "../components/layout.js"
import Modal from "../components/Courses/Modal"
import checkdiscountCode from "../components/Courses/checkdiscountCode"
import displayPrice from "../components/Courses/displayPrice"
import getPrice from "../components/Courses/getPrice"
import getImagePath from "../components/Images/getImagePath"

const CoursePage = ({ data, location }) => {
    const [openModal, setOpenModal] = useState(false);
    const [promoCode, setPromoCode] = useState(checkdiscountCode(location.search.substring(10).split("=")[0], data.markdownRemark.frontmatter.discount))
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    const price = frontmatter.price
    const price_Discount = frontmatter.price_Discount
    return (
        <Layout>
            <Header
                title={frontmatter.title}
                picture={frontmatter.image}
                headerColor={frontmatter.headerColor}
                button={<button style={{ backgroundColor: "#ba3434" }} className="button is-rounded is-danger is-medium" onClick={() => setOpenModal(!openModal)}>Accéder pour  {displayPrice(price, price_Discount, promoCode)}</button>}
            />
            <section class="hero">
                <div class="hero-body">
                    <div class="container">
                        <div className="columns is-centered">
                            <div className="column is-8 ">
                                <div
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                openModal={openModal}vbbnvm
                setOpenModal={setOpenModal}
                name={frontmatter.shortTitle}
                images={getImagePath(frontmatter.image, data)}
                displayPrice={displayPrice(price, price_Discount, promoCode)}
                price={getPrice(price, price_Discount, promoCode)}
                stripeId={frontmatter.stripeId}
                discount={promoCode.length > 0 ? promoCode : []}
                setPromoCode={setPromoCode}
                allDiscountCode={data.markdownRemark.frontmatter.discount}
                location={location}
                linkRedirect={`thank-you-${data.markdownRemark.id}-${data.markdownRemark.frontmatter.slug}`}
            />
            <Footer
                price={displayPrice(price, price_Discount, promoCode)}
                color="#FFF"
                actionButton={() => setOpenModal(!openModal)}
            />
        </Layout>
    );
};

export default CoursePage;


export const courseQuery = graphql`
  query CoursePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        id
        frontmatter {
            title
            shortTitle
            price
            price_Discount
            templateKey
            slug
            image
            headerColor
            discount{
                name
                date_limit
                percent
            }
        }
        html
    }
    allFile( filter: { internal: { mediaType: { regex: "images/" } } } ) {
        edges {
          node {
            relativePath
            publicURL
          }
        }
    }
  }
`