import React from 'react';

const Footer = ({price, color, actionButton}) => {
    return (
        <section class="hero">
            <div class="hero-body">
                <div class="container">
                    <div className="columns  is-centered is-vcentered">
                        <div className="column is-one-third">
                            <button style={{backgroundColor: "#ba3434"}} className="button is-rounded is-danger is-medium is-fullwidth" onClick={actionButton}>Accéder pour {price}</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;