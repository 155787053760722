export default function getPrice(price, price_Discount, discountCode) {
    console.log(price, price_Discount, discountCode)
    if(discountCode && discountCode.length > 0){
        const priceAfterdiscountCode = price_Discount === 0 ? (price-(price*discountCode[0].percent/100)) : (price_Discount-(price_Discount*discountCode[0].percent/100))
        return(priceAfterdiscountCode)
    }
    if(price_Discount !== 0){
        return(price_Discount)
    }
    return (price)
}
