import React from 'react'

export default function displayPrice(price, price_Discount, discountCode) {
    if(discountCode && discountCode.length > 0){
        const priceAfterdiscountCode = price_Discount === 0 ? (price-(price*discountCode[0].percent/100)) : (price_Discount-(price_Discount*discountCode[0].percent/100))
        return(
            <div style={{paddingLeft:"10px"}}>
                <span><strike style={{color: "#333"}}>{price} €</strike></span>
                <span style={{paddingLeft:"5px"}}> {priceAfterdiscountCode} €</span>
            </div>
        )
    }
    if(price_Discount !== 0){
        return(
            <div style={{paddingLeft:"10px"}}>
                <span><strike style={{color: "#333"}}>{price} €</strike></span>
                <span style={{paddingLeft:"5px"}}> {price_Discount} €</span>
            </div>
        )
    }
    return (
        <div>
            <span style={{paddingLeft:"10px"}}>  {price} €</span>
        </div>
    )
}
