import React, { useState, useEffect } from 'react';
import checkdiscountCode from "../Courses/checkdiscountCode"

const DiscountForm = ({ discount, setPromoCode, allDiscountCode }) => {
    const [discountValue, setDiscount] = useState(discount.length > 0 ? discount[0].name : "")
    const [messageValide, setMessageValide] = useState(discount.length > 0 && discount[0].name === discountValue && `Le code ${discount[0].name} est valide`)
    const changeMessage = () => {
        if(discount.length > 0){
            setMessageValide(`Le code ${discount[0].name} est valide 🥳`)
        }else{
            setMessageValide(`Le code ${discountValue} n'est pas valide 😔`)
        }
    }
    useEffect(() => {
        if(discountValue !== ""){
            return changeMessage()
        }
    }, [discount])
    
    return (
        <>
            <div className="field" style={{ display: "flex", justifyContent: "center" }}>
                <div class="field has-addons">
                    <div class="control">
                        <input
                            class="input"
                            type="text"
                            placeholder="Entrer votre code promo"
                            onChange={(e) => setDiscount(e.target.value)}
                            value={discountValue}
                        />
                    </div>
                    <div class="control">
                        <a className="button" style={{ margin: "0" }} onClick={() => setPromoCode(checkdiscountCode(discountValue, allDiscountCode)) & changeMessage()}>Vérifier</a>
                    </div>
                </div>
            </div>
            <p className={"help has-text-centered " +(discount.length > 0 ? "has-text-success" : "has-text-danger")}>
                {messageValide} 
            </p>
        </>
    );
};

export default DiscountForm;