import React from 'react';
import Paypal from "gatsby-plugin-paypal";
import { navigate } from "gatsby"

const paypalButton = ({name, price, linkRedirect}) => {
    function createOrder (data, actions) {
        return actions.order.create({
            purchase_units: [{ "description": name, "amount": { "currency_code": "EUR", "value": ""+price } }]
        });
    }

    function onApprove (data, actions) {
        return actions.order.capture().then(() => {
            navigate(linkRedirect)
        });
    }

    /*
    function onApprove(data, actions) {
        return actions.order.get().then(function (orderDetails) {
            return actions.order.capture().then(function (details) {
                console.log(orderDetails, details)
            });
        });
    }*/

    return (
        <Paypal
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
            style={{
                shape: 'pill',
                color: 'blue',
                layout: 'horizontal',
                label: 'paypal'
            }}
        />
    );
};

export default paypalButton;